<template>
    <div>
        <div class="tab-bar">
            <div
                v-for="item in tabBarList"
                :key="item.id"
                :class="{ active: item.id === tabBarType }"
                @click="changeTabbar(item.id)"
            >
                {{ item.name }}
            </div>
        </div>
        <div class="filter-panel">
            <CSSelect style="margin-right: 10px; width: 185px;">
                <el-date-picker
                    v-model="startDate"
                    type="date"
                    style="width: 157px;"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <span
                style="
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 0 15px 0;
                    height: 2px;
                    width: 20px;
                    margin-right: 0;
                    background-color: #999;
                "
            >
            </span>
            <CSSelect style="margin-left: 10px; width: 185px;">
                <el-date-picker
                    v-model="endDate"
                    type="date"
                    style="width: 157px;"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <input
                type="text"
                class="cs-input"
                style="margin-right: 30px;"
                placeholder="搜索用户信息"
                v-model="userInfo"
            />
            <button class="btn btn-primary" @click="getBillList()">查询</button>
        </div>

        <div class="result-panel">
            <template v-if="tabBarType === BILL_TYPE.PAYED">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:header>
                        <div class="result-panel-header">
                            累计已付金额:
                            {{ billList.totalPaid | realMoney }}
                        </div>
                    </template>
                    <template v-slot:thead>
                        <tr>
                            <th >付款时间</th>
                            <th >会议室名称/房间号</th>
                            <th >单价</th>
                            <th >实际使用时间</th>
                            <th >已付金额</th>
                            <th >已退金额</th>
                            <th >用户信息</th>
                            <th >支付渠道</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <template
                            v-if="billList.data && billList.data.length > 0"
                        >
                            <tr
                                v-for="(bill, index) in billList.data"
                                :key="index"
                            >
                                <td class="date-td">
                                    {{ bill.paymentTime || '-' }}
                                </td>
                                <td >
                                    {{
                                        bill.buildingName + "-" + bill.roomName
                                    }}
                                </td>
                                <td >
                                    {{ bill.price ? bill.price + '元' : '-' }}
                                </td>
                                <td >
                                    {{ bill.actualUseDuration || '-' }}
                                </td>
                                <td >
                                    {{ bill.moneyAmount ? bill.moneyAmount + '元' : '-' }}
                                </td>
                                <td >
                                    {{ bill.refundMoney ? bill.refundMoney + '元' : '-'}}
                                </td>

                                <td >
                                    <div>
                                        <p style="margin-bottom: 0px;">
                                            {{ bill.userName }}
                                            【{{ bill.userPhone }}】
                                        </p>
                                        <p style="margin-bottom: 0;">{{ bill.userCompany }}</p>
                                        <p style="margin-bottom: 0px;" v-if="bill.userCompanyAddr">{{ bill.userCompanyAddr }}</p>
                                    </div>
                                </td>
                                <td>
                                    {{ PAY_CHANNEL_MAPPING[bill.payChannel] || '-' }}
                                </td>
                            </tr>
                        </template>
                    </template>
                </CSTable>
            </template>
            <template v-else>
                <CSTable :thead-top="filterHeight">
                    <template v-slot:header>
                        <div class="result-panel-header" style="border-bottom: 1px solid #ddd;">
                            累计已退金额: {{ billList.totalRefund | realMoney }}
                        </div>
                    </template>
                    <template v-slot:thead>
                        <tr>
                            <th >退款时间</th>
                            <th >会议室房间号</th>
                            <th >单价</th>
                            <th >实际使用时间</th>
                            <th >已付金额</th>
                            <th >已退金额</th>
                            <th >用户信息</th>
                            <th >支付渠道</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <template
                            v-if="billList.data && billList.data.length > 0"
                        >
                            <tr
                                v-for="(bill, index) in billList.data"
                                :key="index"
                            >
                                <td class="date-td">
                                    {{ bill.refundTime || '-' }}
                                </td>
                                <td >
                                    {{
                                        bill.buildingName + "-" + bill.roomName
                                    }}
                                </td>
                                <td >
                                    {{ bill.price ? bill.price + '元' : '-' }}
                                </td>
                                <td >
                                    {{ bill.actualUseDuration || '-' }}
                                </td>
                                <td >
                                    {{ bill.moneyAmount ? bill.moneyAmount + '元' : '-' }}
                                </td>
                                <td >
                                    {{ bill.refundMoney ? bill.refundMoney + '元' : '-' }}
                                </td>
                                <!--                                <td >
                                                                    {{ bill.reservationTime || '-' }}
                                                                </td>-->
                                <td >
                                    <p style="margin-bottom: 0px;">
                                        {{ bill.userName }}
                                        【{{ bill.userPhone }}】
                                    </p>
                                    <p style="margin-bottom: 0;">{{ bill.userCompany }}</p>
                                    <p style="margin-bottom: 0px;" v-if="bill.userCompanyAddr">{{ bill.userCompanyAddr }}</p>
                                </td>
                                <td>
                                    {{ PAY_CHANNEL_MAPPING[bill.payChannel] || '-' }}
                                </td>
                            </tr>
                        </template>
                    </template>
                </CSTable>
            </template>
            <Pagination />
        </div>
        <ViewModal></ViewModal>

    </div>
</template>

<script>
import ViewModal from "@/components/ViewModal";
import CSSelect from "@/components/common/CSSelect";
import { queryMeetingRoomBillUrl } from "@/requestUrl";
import dayjs from "dayjs";
import Pagination from "@/components/Pagination.vue";
import { PAY_CHANNEL, PAY_CHANNEL_MAPPING } from "@/constant";
import CSTable from "@/components/common/CSTable";

const BILL_TYPE = {
    PAYED: 1,
    REFUND: 2,
};

export default {
    name: "meetingRoomBill",
    components: {
        CSTable,
        ViewModal,
        Pagination,
        CSSelect,
    },
    data() {
        return {
            BILL_TYPE,
            PAY_CHANNEL_MAPPING,
            filterHeight: 0,
            startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
            userInfo: "",
            regionId: this.$vc.getCurrentRegion().communityId,
            tabBarType: BILL_TYPE.PAYED,
            tabBarList: [
                { name: "付款", id: BILL_TYPE.PAYED },
                { name: "已退款", id: BILL_TYPE.REFUND },
            ],
            billList: [],
        };
    },
    filters: {
        realMoney(val) {
            return val ? val  + "元" : '-';
        },
    },
    created(){
        window.addEventListener("keydown",this.getBillListDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getBillListDown);
    },
    deactivated(){
         window.removeEventListener("keydown",this.getBillListDown);
    },
    mounted() {
        this.getBillList();
        this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
            this.getBillList(_currentPage);
        });

        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods: {
        getBillListDown(e){
            if(e.keyCode == 13){
                console.log(46454);
                this.getBillList();
            }
        },
        /**
         * 切换tabBar
         * @param {Number} type
         *  */
        changeTabbar(type) {
            if (this.tabBarType === type) {
                return;
            }
            this.tabBarType = type;
            this.getBillList();
        },
        //  获取退款记录
        getBillList(pageNo = 1, pageSize = 10) {
            const {
                startDate,
                endDate,
                userInfo,
                regionId,
                tabBarType: state,
            } = this;
            this.$fly
                .post(queryMeetingRoomBillUrl, {
                    startDate,
                    endDate,
                    userInfo,
                    regionId,
                    state,
                    pageNo,
                    pageSize,
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    if(pageNo === 1){
                        this.$vc.emit(this.$route.path, "pagination","init",{
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize,
                        })
                    }
                    this.billList = res.data;
                });
        },
        /**
         * 查看用户评价
         * @param {Object} logInfo log信息
         *  */
        lookEvaluate(logInfo) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                content: `
                    <div class="panel panel-default">
                        <div class="panel-body">
                            <p>${logInfo.score}星, ${logInfo.evaluateTime}</p>
                            <p>${logInfo.evaluate}</p>
                        </div>
                    </div>
                `,
            });
        },
        /**
         * 查看退款原因
         * @param {String} reason
         *  */
        lookRefundReason(reason) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                content: reason,
            });
        },
    },
};
</script>
